import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  Tabs,
  Tab,
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  InputLabel
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Avatar from 'react-avatar-edit';
import * as filestack from 'filestack-js';
import uuidv4 from 'uuid/v4';
import TimezonePicker from 'components/TimezonePicker';
import Box from 'components/Box';
import { apolloClientQuery, apolloClientMutation } from 'middleware/api';
import { ASSETS_GET_UL_POLICY } from 'gql/asset';
import { UPDATE_USER_PROFILE, UPDATE_USER_AVATAR, DELETE_USER_AVATAR } from 'gql/user';
import { showToast } from 'contexts/ToastContext';
import AlbLoading from 'components/AlbLoading';
import AlembicModalForm from 'components/AlembicModalForm';
import TwoFactorControl from 'components/TwoFactorControl';
import UserSessionManager from 'components/UserSessionManager';
import { DefaultUserAvatarColor } from 'util/assets';
import setPageTitle from 'util/titles';
import { withRouter, useParams } from 'react-router-dom';
import { updateUser } from 'actions/authActions';
import { UserProfilePath } from '../../util/paths';

const ProfileTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

ProfileTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ProfileCommitButton = props => {
  const { loading } = props;

  if (loading) {
    return (
      <Grid item xs={12}>
        <AlbLoading />
      </Grid>
    );
  }

  // submit here will trigger the validator form.
  return (
    <Grid item xs={12}>
      <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading} p={3}>
        Update Profile
      </Button>
    </Grid>
  );
};

ProfileCommitButton.propTypes = {
  loading: PropTypes.bool.isRequired
};

const Profile = props => {
  const { currentUser, history } = props;
  const { containerId, tabId } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const [preview, setPreview] = useState(null);
  const [chooseAvatar, setChooseAvatar] = useState(false);
  const [cropAvatar, setCropAvatar] = useState(false);
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [avatarUpdating, setAvatarUpdating] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bio, setBio] = useState('');
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [timeZone, setTimeZone] = useState(currentUser.time_zone || '');
  const [updateProfile, { loading, error, data }] = useMutation(UPDATE_USER_PROFILE);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    // if they did not set the tab id, we will default to the first tab.
    if (!tabId) {
      history.push(`/${containerId}${UserProfilePath}/0`);
    } else {
      setCurrentTab(parseInt(tabId, 10));
    }

    setPageTitle('Profile & Settings');
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.first_name || '');
      setLastName(currentUser.last_name || '');
      setBio(currentUser.bio || '');

      setTitle(currentUser.title || '');
      setCompany(currentUser.company || '');
      setEmail(currentUser.email || '');
      setPhone(currentUser.phone || '');
      setTimeZone(currentUser.time_zone || '');
    }
  }, []);

  useEffect(() => {
    if (data && data.updateUserProfile.result) {
      showToast('Profile Updated', 'success');
      updateUser();
    }

    if (data && !data.updateUserProfile.result) {
      showToast(data.updateUserProfile.error, 'error');
    }
    // on the first load we will show ALBLoading, subsequent loads will just update the button.
    setFirstLoad(false);
  }, [data]);

  useEffect(() => {
    if (error?.message) {
      showToast(error.message, 'error');
    }
  }, [error]);

  const handleProfileSubmit = event => {
    // ignore enter as a shortcut to submit the profile on the MFA page. This will be handled by the button.
    if (currentTab !== 2) {
      updateProfile({
        variables: {
          first_name: firstName,
          last_name: lastName,
          company,
          phone,
          time_zone: timeZone,
          bio,
          title
        }
      }).catch(() => {});

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleAvatarSubmit = async () => {
    setAvatarUpdating(true);
    try {
      const uploadPolicy = await apolloClientQuery(ASSETS_GET_UL_POLICY, {});
      const client = filestack.init(process.env.FS_API_KEY);

      const uploadOptions = {};
      const storeOptions = {
        filename: `/${process.env.NODE_ENV || 'development'}/avatars/${uuidv4()}`
      };
      const token = {};
      const security = {
        policy: uploadPolicy.data.getUploadPolicy.policy,
        signature: uploadPolicy.data.getUploadPolicy.signature
      };

      const { handle } = await client.upload(preview, uploadOptions, storeOptions, token, security);
      if (handle) {
        const uploadResult = await apolloClientMutation(UPDATE_USER_AVATAR, { handle });
        if (uploadResult && uploadResult.data.updateUserAvatar.result) {
          showToast('Upload Success', 'success');

          // this will trigger a repaint.
          updateUser();
          setAvatarUpdating(false);
        }

        if (uploadResult?.errors?.[0]?.message) {
          showToast(uploadResult.errors?.[0]?.message, 'error');
        }
      }
    } catch (e) {
      showToast('Could not upload profile picture', 'error');
    }
  };

  const handleAvatarDelete = async () => {
    try {
      const deleteResult = await apolloClientMutation(DELETE_USER_AVATAR);
      if (deleteResult && deleteResult.data.deleteUserAvatar.result) {
        showToast('Successfully deleted profile picture.', 'success');
        updateUser();
      }

      if (deleteResult && !deleteResult.data.deleteUserAvatar.result) {
        showToast(deleteResult.deleteUserAvatar.message, 'error');
      }
    } catch (e) {
      showToast('Could not delete profile picture', 'error');
    }

    setDeleteAvatar(false);
  };

  const deleteFields = [
    {
      title: 'Are you sure you want to delete the profile picture?',
      type: 'static'
    }
  ];

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} md={10}>
          <Box p={30}>
            <Grid item xs={10}>
              <Typography variant="h1">Profile &amp; Settings</Typography>
            </Grid>
          </Box>

          <Paper>
            <ValidatorForm onSubmit={handleProfileSubmit}>
              <Box p={30}>
                <Grid item xs={12}>
                  <Tabs
                    value={currentTab}
                    onChange={(e, v) => {
                      history.replace({ pathname: `/${containerId}${UserProfilePath}/${v}` });
                      setCurrentTab(v);
                    }}
                  >
                    <Tab label="Personal Info" id={0} />
                    <Tab label="Account Settings" id={1} />
                    <Tab label="Security" id={2} />
                    <Tab label="Sessions" id={3} />
                  </Tabs>

                  {loading && firstLoad && <AlbLoading />}

                  <ProfileTabPanel value={currentTab} index={0}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      <Grid item>
                        {/* Current Avatar Display */}
                        {!chooseAvatar && !cropAvatar && (
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '200px',
                                minWidth: '200px'
                              }}
                            >
                              {currentUser.avatar_url && !avatarUpdating && (
                                <Grid
                                  item
                                  style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    zIndex: 1
                                  }}
                                >
                                  <IconButton onClick={() => setDeleteAvatar(true)} size="small">
                                    <Cancel />
                                  </IconButton>
                                </Grid>
                              )}
                              {avatarUpdating && <AlbLoading />}
                              {!avatarUpdating && (
                                <img
                                  src={currentUser.avatar_url || DefaultUserAvatarColor}
                                  alt="User's Avatar"
                                  height="100%"
                                  width="100%"
                                  style={{ opactity: currentUser.avatar_url ? 1 : 0.5 }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )}
                        {/* Avatar Editor */}
                        {(chooseAvatar || cropAvatar) && (
                          <Grid container justifyContent="center">
                            <Grid item xs={12}>
                              <Avatar
                                width={200}
                                height={200}
                                onCrop={image => setPreview(image)}
                                onClose={() => {
                                  setPreview(null);
                                  setChooseAvatar(true);
                                }}
                                onFileLoad={() => {
                                  setCropAvatar(true);
                                  setChooseAvatar(false);
                                }}
                                label="Click to Upload"
                                labelStyle={{ fontSize: '12px', fontWeight: 'bold' }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {/* first step - brings up the editor */}
                        {!chooseAvatar && !cropAvatar && !avatarUpdating && (
                          <Box pb={20}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setChooseAvatar(true)}
                            >
                              {currentUser.avatar_url ? 'Update' : 'Upload'} Profile Picture
                            </Button>
                          </Box>
                        )}
                        {/* second step */}
                        {chooseAvatar && (
                          <Box p={10}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setCropAvatar(false);
                                setChooseAvatar(false);
                                setPreview(null);
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        )}
                        {/* third step - upload confirmation, image is selected and previewed */}
                        {preview && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setCropAvatar(false);
                              setChooseAvatar(false);
                              setPreview(null);
                              handleAvatarSubmit();
                            }}
                          >
                            Crop Profile Picture
                          </Button>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={10}>
                      <Grid item xs={12} md={6}>
                        <InputLabel>First Name</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="John"
                          value={firstName}
                          onChange={event => setFirstName(event.target.value)}
                          validators={['required']}
                          errorMessages={['* Required']}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Last Name</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="Doe"
                          value={lastName}
                          onChange={event => setLastName(event.target.value)}
                          validators={['required']}
                          errorMessages={['* Required']}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputLabel>Company&nbsp;/&nbsp;Project&nbsp;Name</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="🔥 Alembic"
                          value={company}
                          onChange={event => setCompany(event.target.value)}
                          validators={['required']}
                          errorMessages={['* Required']}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Mobile Phone</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="415-123-4567"
                          value={phone}
                          onChange={event => setPhone(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <InputLabel>Email</InputLabel>
                        <TextValidator
                          fullWidth
                          disabled
                          type="text"
                          variant="filled"
                          placeholder="you@example.com"
                          value={email}
                          onChange={event => setEmail(event.target.value)}
                          validators={['required', 'isEmail']}
                          errorMessages={['* Required', 'Invalid Email']}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Title</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="CMO"
                          value={title}
                          onChange={event => setTitle(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Bio</InputLabel>
                        <TextValidator
                          fullWidth
                          type="text"
                          variant="filled"
                          placeholder="Marketer to the stars"
                          value={bio}
                          onChange={event => setBio(event.target.value)}
                        />
                      </Grid>
                      <ProfileCommitButton loading={loading} />
                    </Grid>
                  </ProfileTabPanel>
                  <ProfileTabPanel value={currentTab} index={1}>
                    <Grid item xs={12}>
                      <Box p={30}>
                        <InputLabel>Time Zone</InputLabel>
                        <TimezonePicker value={timeZone} onChange={value => setTimeZone(value)} />
                      </Box>
                    </Grid>
                    <ProfileCommitButton loading={loading} />
                  </ProfileTabPanel>
                  <ProfileTabPanel value={currentTab} index={2}>
                    <Box p={30}>
                      {loading && (
                        <Grid item xs={12}>
                          <Box mb={12}>
                            <AlbLoading />
                          </Box>
                        </Grid>
                      )}
                      <Typography variant="h1">Multi-Factor Authentication</Typography>
                      <Box mt={20}>
                        <TwoFactorControl />
                      </Box>
                    </Box>
                  </ProfileTabPanel>
                  <ProfileTabPanel value={currentTab} index={3}>
                    <Box mt={20}>
                      <UserSessionManager />
                    </Box>
                  </ProfileTabPanel>
                </Grid>
              </Box>
            </ValidatorForm>
          </Paper>
        </Grid>
        <AlembicModalForm
          title="Delete Profile Picture"
          saveTitle="Delete"
          cancelTitle="Cancel"
          open={deleteAvatar}
          onClose={() => setDeleteAvatar(false)}
          handleConfirm={handleAvatarDelete}
          fields={deleteFields}
          currentObject={{}}
          variant="delete"
        />
      </Grid>
    </>
  );
};

Profile.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(withRouter(Profile));
