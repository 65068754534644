import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import SummaryOfSummaries from './SummaryOfSummaries';
// import DateView from './DateView';
import InsightsTabPanel from './InsightsTabPanel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
  children: null
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: 'auto'
  },
  panel: {
    padding: '0 90px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.10)',
    width: '100%',
    '& .MuiBox-root': {
      padding: '63px 0'
    }
  },
  monthlyPanel: {
    height: '100vh'
  },
  tabs: {
    overflow: 'visible',
    padding: '62px 0 0 0',
    '& .MuiTab-root': {
      backgroundColor: '#F9F9F9',
      margin: '2px 0px 0px 0px',
      borderRadius: '0 5px 5px 0',
      lineHeight: 0,
      minHeight: 'unset',
      padding: '16px',
      color: '#0A1934'
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: '#fff',
      fontWeight: 700
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '5px',
    padding: '20px 0 0 0',
    marginBottom: '48px'
  },
  title: {
    fontSize: '28px',
    fontWeight: 500,
    margin: '0 0 40px 0'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  animate: {
    transition: 'all 1s ease'
  },
  insightRow: {
    justifyContent: 'center'
  },
  summary: {
    display: 'flex'
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#979797',
    margin: '28px 0px'
  }
}));

function InsightsTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`${classes.root} ${classes.box} ${classes.animate}`}>
      {/* <TabPanel value={value} index={0}>
        <DateView dateType=dateType.PreviousDay />
        <h1 className={`${classes.title}`}>Daily Intelligence Briefing</h1>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DateView dateType=dateType.ThisWeek />
        <h1 className={`${classes.title}`}>Weekly Intelligence Briefing</h1>
      </TabPanel> */}
      <TabPanel value={value} index={0} className={`${classes.panel} ${classes.monthlyPanel}`}>
        <InsightsTabPanel timeSlice="Month" />
      </TabPanel>
      <TabPanel value={value} index={1} className={`${classes.panel}`}>
        <InsightsTabPanel timeSlice="Quarter" />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <h1 className={`${classes.title}`}>New Intelligence Briefing</h1>
      </TabPanel> */}
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="executive intelligence tabs"
        className={classes.tabs}
      >
        {/* <Tab label="DAY" {...a11yProps(0)} /> */}
        {/* <Tab label="WEEK" {...a11yProps(1)} /> */}
        <Tab label="MONTH" {...a11yProps(0)} />
        <Tab label="QUARTER" {...a11yProps(1)} />
        {/* <Tab label="+ ADD NEW" {...a11yProps(4)} /> */}
      </Tabs>
    </div>
  );
}

export default InsightsTabs;
