/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Modal } from '@material-ui/core';
import useDimensions from 'react-use-dimensions';
import { ORGANIZATION_SETTINGS } from 'gql/organization';
import { getCurrentTimeInUserTimeZone, mapButtonsToPicker, mapPickerToButtons } from 'util/date';
import RangePicker from './RangePicker';
import CalendarPicker from './CalendarPicker';

const style = makeStyles({
  root: {
    minWidth: 0
  },
  calendarRangeGrid: props => {
    const { marginBottom } = props;

    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      '& > div': {
        marginBottom
      }
    };
  },
  calendarButtons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  calendarButton: {
    padding: '6px 10px'
  },
  calendarModal: {
    position: 'absolute',
    zIndex: '1450 !important'
  }
});

// RangeSelector takes in parameters from date picker and spits out a range for graph querying.
const AnalyticsDatePicker = props => {
  const {
    currentUser,
    setDateRange,
    calRange,
    delta,
    singleDate,
    customArrowRange,
    defaultButtonLabel,
    hideRangeButtons,
    allowStartAfterCurrentDate,
    disableMargin
  } = props;
  const classes = style({ marginBottom: disableMargin ? '0px' : '10px' });
  const weekStart = useRef(0);
  const yearStart = useRef(1);

  const rangeButtons = [
    // current
    { label: '1D', rangeKey: 'd', fixed: true },
    { label: '1W', rangeKey: 'w', fixed: true, offsetKey: 'd', offset: weekStart.current },
    { label: '1M', rangeKey: 'M', fixed: true },
    {
      label: '1Q',
      rangeKey: 'Q',
      fixed: true,
      offsetKey: 'M',
      offset: (yearStart.current - 1 + 3) % 12, // 0-indexed, so add 3 months to get Q1
      modulus: 3
    },
    { label: '1Y', rangeKey: 'y', fixed: true },
    // rolling
    { label: '7', rangeKey: 'd', units: 7 },
    { label: '14', rangeKey: 'd', units: 14 },
    { label: '30', rangeKey: 'd', units: 30 },
    { label: '90', rangeKey: 'd', units: 90 }
  ];

  // used to set buttons
  const [calendarRange, selectCalendarRange] = useState({});
  const [customDatePicker, toggleDatePicker] = useState(false);
  const [calendarAnchor, { x, y }] = useDimensions();

  // make controlled component
  const [dateRange, selectDateRange] = useState({
    start: calRange.start,
    end: calRange.end
  });

  useQuery(ORGANIZATION_SETTINGS, {
    onCompleted: data => {
      const { week_start, year_start } = data.getCurrentOrganization;

      weekStart.current = week_start;
      yearStart.current = year_start;
    },
    // prevent onCompleted from firing every update
    variables: {}
  });

  const onSelect = range => {
    let matchingButton = {};

    // don't want to match to a button for certain cases.
    if (!allowStartAfterCurrentDate) {
      matchingButton = mapPickerToButtons(range, rangeButtons);
    }
    selectDateRange(range);
    selectCalendarRange(matchingButton);
  };

  // set fixed range or find button if no button has been selected
  const handleSetRange = button => {
    const { rangeKey, offsetKey, offset } = button;
    let { start } = dateRange;
    let { end } = dateRange;

    let selectedButton = {};
    let mappedButton = {};

    // no rangeKey means no button has been passed in. We check if any button matches the range.
    if (!rangeKey && !allowStartAfterCurrentDate) {
      // check if selected range matches any range buttons. Highlight the right button.
      mappedButton = mapPickerToButtons({ start, end }, rangeButtons);

      selectedButton = mappedButton;
    }

    if (rangeKey) {
      start = getCurrentTimeInUserTimeZone(currentUser.time_zone).startOf(rangeKey);
      end = getCurrentTimeInUserTimeZone(currentUser.time_zone).endOf(rangeKey);

      if (offsetKey) {
        start.add(offset, offsetKey);
        end.add(offset, offsetKey);

        while (getCurrentTimeInUserTimeZone(currentUser.time_zone).isBefore(start)) {
          start.subtract(1, rangeKey);
          end.subtract(1, rangeKey);
        }
      }

      start = new Date(start.format('L LT'));
      end = new Date(end.format('L LT'));

      selectedButton = button;
    }

    const range = { start, end };

    if (!isEmpty(selectedButton)) {
      selectCalendarRange(selectedButton);
    }
    selectDateRange(range);
    setDateRange(range);
  };

  const handleSetRolling = button => {
    const range = mapButtonsToPicker(button, currentUser);

    selectCalendarRange(button);
    selectDateRange(range);
    setDateRange(range);
  };

  // this will also call handleSetRange if no button is set, thus that method is used for matching a button.
  const handleDateRangeSelection = button =>
    ({}.hasOwnProperty.call(button, 'units') ? handleSetRolling(button) : handleSetRange(button));

  useEffect(() => {
    handleDateRangeSelection(calendarRange);
  }, [calendarRange]);

  useEffect(() => {
    if (customDatePicker) selectCalendarRange({});
  }, []);

  useEffect(() => {
    if (!customArrowRange && defaultButtonLabel) {
      const matchingButton = rangeButtons.find(button => button.label === defaultButtonLabel);

      if (matchingButton) {
        handleDateRangeSelection(matchingButton);
      }
    }
  }, [defaultButtonLabel]);

  useEffect(() => {
    selectDateRange({ start: calRange.start, end: calRange.end });
  }, [calRange]);

  const createDateRangeButton = button => {
    const selected = calendarRange?.label === button.label;

    return (
      <Button
        classes={{
          root: `${classes.root} ${classes.calendarButton}`
        }}
        key={button.label}
        variant={selected ? 'contained' : 'text'}
        color={selected ? 'primary' : 'default'}
        onClick={() => handleDateRangeSelection(button)}
      >
        {button.label}
      </Button>
    );
  };

  return (
    <Grid container={!disableMargin} className={classes.root} ref={calendarAnchor}>
      <Grid item className={classes.calendarRangeGrid}>
        <RangePicker
          callback={() => toggleDatePicker(true)}
          range={dateRange}
          selectedRange={calendarRange}
          setDateRange={range => {
            setDateRange(range);
          }}
          delta={delta}
          singleDate={singleDate}
          customArrowRange={customArrowRange}
          allowStartAfterCurrentDate={allowStartAfterCurrentDate}
        />
        {!hideRangeButtons && (
          <Box className={classes.calendarButtons}>{rangeButtons.map(createDateRangeButton)}</Box>
        )}
        <Modal
          open={customDatePicker}
          onClose={() => {
            onSelect(dateRange);
            toggleDatePicker(false);
          }}
          className={classes.calendarModal}
          style={{ position: 'absolute', top: `${y}px`, left: `${x}px` }}
        >
          <CalendarPicker
            onSelect={onSelect}
            selected={dateRange}
            selectDateRange={selectDateRange}
            callback={() => toggleDatePicker(false)}
            delta={delta}
            singleDate={singleDate}
            allowStartAfterCurrentDate={allowStartAfterCurrentDate}
          />
        </Modal>
      </Grid>
    </Grid>
  );
};

AnalyticsDatePicker.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  setDateRange: PropTypes.func.isRequired,
  calRange: PropTypes.shape({
    start: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment)
    ]),
    end: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment)
    ])
  }).isRequired,
  delta: PropTypes.bool,
  singleDate: PropTypes.bool,
  customArrowRange: PropTypes.string,
  defaultButtonLabel: PropTypes.string,
  hideRangeButtons: PropTypes.bool,
  allowStartAfterCurrentDate: PropTypes.bool,
  disableMargin: PropTypes.bool
};

AnalyticsDatePicker.defaultProps = {
  delta: false,
  singleDate: false,
  customArrowRange: null,
  defaultButtonLabel: '14',
  hideRangeButtons: false,
  allowStartAfterCurrentDate: false,
  disableMargin: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(AnalyticsDatePicker);
