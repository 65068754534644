/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MDXContent from 'components/MDXContent';
import EventLink from './EventLink';

const components = { EventLink };

const useStyles = makeStyles({
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    },
    '& hr': {
      marginTop: '40px',
      marginBottom: '40px'
    }
  }
});

/**
 * @method
 * @summary This component renders the summary of summaries for the GraphFish UI
 * @name SummaryOfSummaries
 * @return {Object} - React JSX
 */
const SummaryOfSummaries = props => {
  const classes = useStyles();
  const { summary } = props;

  return (
    <div className={`${classes.markdown}`}>
      <MDXContent components={components} content={summary} />
    </div>
  );
};

SummaryOfSummaries.propTypes = {
  summary: PropTypes.string.isRequired
};

export default SummaryOfSummaries;
