export const LINKTOKEN_EVENT_TYPE = {
  // Social
  RESURGE_POST: 'RESURGE_POST',
  RESURGE_MULTIPOST: 'RESURGE_MULTIPOST',
  INITIAL_SURGE: 'INITIAL_SURGE',

  // Web
  OUTLIER_SOURCE_GA3: 'OUTLIER_SOURCE_GA3',
  OUTLIER_SOURCE_GA4: 'OUTLIER_SOURCE_GA4',
  OUTLIER_PAGE_GA3: 'OUTLIER_PAGE_GA3',
  OUTLIER_PAGE_GA4: 'OUTLIER_PAGE_GA4',

  // Conversion
  OUTLIER_GOAL_GA3: 'OUTLIER_GOAL_GA3',
  OUTLIER_GOAL_GA4: 'OUTLIER_GOAL_GA4',

  // Not used
  RESURGE_MULTI_GA: 'RESURGE_MULTI_GA', // does not exist yet
  GENERIC_EVENT: 'GENERIC_EVENT' // this is for events that don't fit -- see the metadata field to store the event type
};

export const DETECTED_EVENT_TYPE = {
  // Social
  IM_POST_RESURGE: 'IM_POST_RESURGE',
  IM_LINKTOKEN_RESURGE: 'IM_LINKTOKEN_RESURGE',
  IM_SINGLE_POST_INITIAL_SURGE: 'IM_SINGLE_POST_INITIAL_SURGE',
  IM_DAILY_POST_INITIAL_SURGE: 'IM_DAILY_POST_INITIAL_SURGE',
  EG_POST_RESURGE: 'EG_POST_RESURGE',
  EG_LINKTOKEN_RESURGE: 'EG_LINKTOKEN_RESURGE',
  EG_SINGLE_POST_INITIAL_SURGE: 'EG_SINGLE_POST_INITIAL_SURGE',
  EG_DAILY_POST_INITIAL_SURGE: 'EG_DAILY_POST_INITIAL_SURGE',
  // FL_POST_RESURGE: 'FL_POST_RESURGE',
  // FL_LINKTOKEN_RESURGE: 'FL_LINKTOKEN_RESURGE',
  // FL_SINGLE_POST_INITIAL_SURGE: 'FL_SINGLE_POST_INITIAL_SURGE',
  // FL_DAILY_POST_INITIAL_SURGE: 'FL_DAILY_POST_INITIAL_SURGE',

  // Web: Google Analytics
  GA_USERS_BY_SOURCE: 'GA_USERS_BY_SOURCE',
  GA4_TOTAL_USERS_BY_SOURCE: 'GA4_TOTAL_USERS_BY_SOURCE',
  GA_USERS_BY_PAGE_PATH: 'GA_USERS_BY_PAGE_PATH',
  GA4_TOTAL_USERS_BY_PAGE_PATH: 'GA4_TOTAL_USERS_BY_PAGE_PATH',
  GA_USERS_BY_AD_MATCHED_QUERY: 'GA_USERS_BY_AD_MATCHED_QUERY',
  GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY: 'GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY',
  GA_USERS_BY_SEARCH_KEYWORD: 'GA_USERS_BY_SEARCH_KEYWORD',
  GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM: 'GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM',
  GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY: 'GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY',
  GA_USERS_BY_CHANNEL_GROUPING: 'GA_USERS_BY_CHANNEL_GROUPING',
  GA_USERS_BY_CAMPAIGN: 'GA_USERS_BY_CAMPAIGN',
  GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING: 'GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING',
  GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME: 'GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME',
  GA_USERS_BY_KEYWORD: 'GA_USERS_BY_KEYWORD',
  GA4_TOTAL_USERS_BY_SEARCH_TERM: 'GA4_TOTAL_USERS_BY_SEARCH_TERM',
  GA_INTERACTIONS_BY_GOAL: 'GA_INTERACTIONS_BY_GOAL',
  GA4_INTERACTIONS_BY_GOAL: 'GA4_INTERACTIONS_BY_GOAL',

  // Web: Adobe Analytics
  AA_USERS_BY_SOURCE: 'AA_USERS_BY_SOURCE',
  AA_USERS_BY_PAGE_PATH: 'AA_USERS_BY_PAGE_PATH',
  AA_USERS_BY_SEARCH_KEYWORD: 'AA_USERS_BY_SEARCH_KEYWORD',
  AA_USERS_BY_PAID_SEARCH: 'AA_USERS_BY_PAID_SEARCH',
  AA_USERS_BY_ON_SITE_SEARCH: 'AA_USERS_BY_ON_SITE_SEARCH',
  AA_USERS_BY_MARKETING_CHANNEL: 'AA_USERS_BY_MARKETING_CHANNEL',
  AA_USERS_BY_CAMPAIGN: 'AA_USERS_BY_CAMPAIGN',
  AA_INTERACTIONS_BY_EVENT: 'AA_INTERACTIONS_BY_EVENT',
  AA_UNIQUE_VISITORS: 'AA_UNIQUE_VISITORS',

  // Web: Shortlink
  SL_CLICKS: 'SL_CLICKS',

  // Web: Google Play Store
  GO_ACTIVE_DEVICE_INSTALLS: 'GO_ACTIVE_DEVICE_INSTALLS',

  // Conversion: Google Analytics
  GA_CONVERSIONS_BY_GOAL: 'GA_CONVERSIONS_BY_GOAL',
  GA_REVENUE_BY_PRODUCT: 'GA_REVENUE_BY_PRODUCT',
  GA_REVENUE_BY_SOURCE_MEDIUM: 'GA_REVENUE_BY_SOURCE_MEDIUM',
  GA_QUANTITY_BY_PRODUCT: 'GA_QUANTITY_BY_PRODUCT',
  GA_REVENUE_PER_TRANSACTION: 'GA_REVENUE_PER_TRANSACTION',
  GA_TRANSACTIONS_PER_SESSION: 'GA_TRANSACTIONS_PER_SESSION',
  GA4_TRANSACTIONS_PER_PURCHASER: 'GA4_TRANSACTIONS_PER_PURCHASER',
  GA4_REVENUE_BY_ITEM_NAME: 'GA4_REVENUE_BY_ITEM_NAME',
  GA4_REVENUE_BY_SESSION_SOURCE: 'GA4_REVENUE_BY_SESSION_SOURCE',
  GA4_QUANTITY_BY_ITEM_NAME: 'GA4_QUANTITY_BY_ITEM_NAME',
  GA4_CONVERSIONS_BY_GOAL: 'GA4_CONVERSIONS_BY_GOAL',
  GA_REVENUE_BY_CHANNEL_GROUPING: 'GA_REVENUE_BY_CHANNEL_GROUPING',
  GA_REVENUE_BY_CAMPAIGN: 'GA_REVENUE_BY_CAMPAIGN',
  GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING: 'GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING',
  GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME: 'GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME',

  // Conversion: Adobe Analytics
  AA_CONVERSIONS_BY_EVENT: 'AA_CONVERSIONS_BY_EVENT',
  AA_REVENUE_BY_SOURCE: 'AA_REVENUE_BY_SOURCE',
  AA_REVENUE_BY_CAMPAIGN: 'AA_REVENUE_BY_CAMPAIGN',
  AA_REVENUE_BY_CHANNEL: 'AA_REVENUE_BY_CHANNEL',
  AA_REVENUE_BY_FARE_TYPE: 'AA_REVENUE_BY_FARE_TYPE',
  AA_TRANSACTIONS_BY_CAMPAIGN: 'AA_TRANSACTIONS_BY_CAMPAIGN',
  AA_TRANSACTIONS_BY_CHANNEL: 'AA_TRANSACTIONS_BY_CHANNEL',
  AA_TRANSACTIONS_BY_FARE_TYPE: 'AA_TRANSACTIONS_BY_FARE_TYPE',
  AA_QUANTITY_BY_CAMPAIGN: 'AA_QUANTITY_BY_CAMPAIGN',
  AA_QUANTITY_BY_CHANNEL: 'AA_QUANTITY_BY_CHANNEL',
  AA_QUANTITY_BY_FARE_TYPE: 'AA_QUANTITY_BY_FARE_TYPE',
  AA_SKYMILES_USED_BY_CAMPAIGN: 'AA_SKYMILES_USED_BY_CAMPAIGN',
  AA_SKYMILES_USED_BY_CHANNEL: 'AA_SKYMILES_USED_BY_CHANNEL',
  AA_SKYMILES_USED_BY_FARE_TYPE: 'AA_SKYMILES_USED_BY_FARE_TYPE',
  AA_BOOKINGS_BY_CAMPAIGN: 'AA_BOOKINGS_BY_CAMPAIGN',
  AA_BOOKINGS_BY_MARKETING_CHANNEL: 'AA_BOOKINGS_BY_MARKETING_CHANNEL',
  AA_CALLS_BY_CAMPAIGN: 'AA_CALLS_BY_CAMPAIGN',
  AA_CALLS_BY_MARKETING_CHANNEL: 'AA_CALLS_BY_MARKETING_CHANNEL',

  // Conversion: Apple App Store
  AP_INSTALLS_BY_DEVICE: 'AP_INSTALLS_BY_DEVICE',
  AP_PURCHASE_BY_PRODUCT_BY_DEVICE: 'AP_PURCHASE_BY_PRODUCT_BY_DEVICE',
  AP_REVENUE_BY_PRODUCT_BY_DEVICE: 'AP_REVENUE_BY_PRODUCT_BY_DEVICE',

  // Conversion: Google Play Store
  GO_INSTALLS: 'GO_INSTALLS',
  GO_PURCHASES_BY_PRODUCT: 'GO_PURCHASES_BY_PRODUCT',
  GO_REVENUE_BY_PRODUCT: 'GO_REVENUE_BY_PRODUCT',
  GO_UNINSTALL_EVENTS: 'GO_UNINSTALL_EVENTS',

  // Conversion: Salesforce
  SF_LEADS_LEADS: 'SF_LEADS_LEADS',
  SF_OPPOS_OPPORTUNITIES: 'SF_OPPOS_OPPORTUNITIES',
  SF_LEADS_BY_SOURCE: 'SF_LEADS_BY_SOURCE',
  SF_LEADS_BY_PROGRAM: 'SF_LEADS_BY_PROGRAM',
  SF_OPPORTUNITIES_BY_SOURCE: 'SF_OPPORTUNITIES_BY_SOURCE',
  SF_REVENUE_BY_SOURCE: 'SF_REVENUE_BY_SOURCE',
  SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN: 'SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN',
  SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN: 'SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN',

  // Media: Social Animal
  SA_ARTICLE_COUNT: 'SA_ARTICLE_COUNT',
  SA_TOTAL_SHARES: 'SA_TOTAL_SHARES',

  // Media: TV Eyes
  BC_RADIO_MENTIONS: 'BC_RADIO_MENTIONS',
  BC_TV_MENTIONS: 'BC_TV_MENTIONS',
  BC_TV_MENTIONS_WITH_VIEWERSHIP: 'BC_TV_MENTIONS_WITH_VIEWERSHIP',
  BC_TV_VIEWERSHIP: 'BC_TV_VIEWERSHIP',
  PC_SUMMARY_MENTIONS: 'PC_SUMMARY_MENTIONS',
  PC_SUMMARY_MENTIONS_WITH_AUDIENCE: 'PC_SUMMARY_MENTIONS_WITH_AUDIENCE',
  PC_TRANSCRIPT_MENTIONS: 'PC_TRANSCRIPT_MENTIONS',
  PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE: 'PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE',
  PC_TRANSCRIPT_AUDIENCE: 'PC_TRANSCRIPT_AUDIENCE',
  NC_BC_TV_NARRATIVE_OCCURRENCES: 'NC_BC_TV_NARRATIVE_OCCURRENCES',
  NC_BC_RADIO_NARRATIVE_OCCURRENCES: 'NC_BC_RADIO_NARRATIVE_OCCURRENCES',
  NC_PC_SUMMARY_NARRATIVE_OCCURRENCES: 'NC_PC_SUMMARY_NARRATIVE_OCCURRENCES',
  NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES: 'NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES',
  NC_BC_TV_NARRATIVE_RATINGS_LOCAL: 'NC_BC_TV_NARRATIVE_RATINGS_LOCAL',
  NC_BC_TV_NARRATIVE_RATINGS_NATIONAL: 'NC_BC_TV_NARRATIVE_RATINGS_NATIONAL',
  NC_PC_TRANSCRIPT_NARRATIVE_RATINGS: 'NC_PC_TRANSCRIPT_NARRATIVE_RATINGS',

  // NASDAQ
  NS_FUND_FLOW: 'NS_FUND_FLOW',

  // Starbucks
  SB_REVENUE_BY_STORE: 'SB_REVENUE_BY_STORE',
  SB_REVENUE_BY_CHANNEL: 'SB_REVENUE_BY_CHANNEL',
  SB_REVENUE_BY_PRODUCT: 'SB_REVENUE_BY_PRODUCT',
  SB_REVENUE_BY_LOYALTY_PROGRAM: 'SB_REVENUE_BY_LOYALTY_PROGRAM',
  SB_REVENUE_BY_DISCOUNT1: 'SB_REVENUE_BY_DISCOUNT1',
  SB_REVENUE_BY_DISCOUNT2: 'SB_REVENUE_BY_DISCOUNT2',
  SB_TRANSACTIONS_BY_STORE: 'SB_TRANSACTIONS_BY_STORE',
  SB_TRANSACTIONS_BY_CHANNEL: 'SB_TRANSACTIONS_BY_CHANNEL',
  SB_QUANTITY_BY_PRODUCT: 'SB_QUANTITY_BY_PRODUCT',
  SB_QUANTITY_BY_LOYALTY_PROGRAM: 'SB_QUANTITY_BY_LOYALTY_PROGRAM',
  SB_QUANTITY_BY_DISCOUNT1: 'SB_QUANTITY_BY_DISCOUNT1',
  SB_QUANTITY_BY_DISCOUNT2: 'SB_QUANTITY_BY_DISCOUNT2',
  SP_FOOTTRAFFIC_BY_STORE: 'SP_FOOTTRAFFIC_BY_STORE',

  // Amplifon (Miracle-Ear)
  ME_APPOINTMENTS_BY_STATE: 'ME_APPOINTMENTS_BY_STATE',
  ME_APPOINTMENTS_BY_SEGMENT: 'ME_APPOINTMENTS_BY_SEGMENT',
  ME_OPPORTUNITIES_BY_STATE: 'ME_OPPORTUNITIES_BY_STATE',
  ME_OPPORTUNITIES_BY_SEGMENT: 'ME_OPPORTUNITIES_BY_SEGMENT',
  ME_REVENUE_BY_STATE: 'ME_REVENUE_BY_STATE',
  ME_REVENUE_BY_SEGMENT: 'ME_REVENUE_BY_SEGMENT',
  ME_QUANTITY_BY_STATE: 'ME_QUANTITY_BY_STATE',
  ME_QUANTITY_BY_SEGMENT: 'ME_QUANTITY_BY_SEGMENT'
};

export const DETECTED_EVENT_TITLE = {
  // Social
  [LINKTOKEN_EVENT_TYPE.RESURGE_POST]: 'Post Resurgence',
  [LINKTOKEN_EVENT_TYPE.RESURGE_MULTIPOST]: 'Multi-Post Resurgence',
  [LINKTOKEN_EVENT_TYPE.INITIAL_SURGE]: 'Viral New Post',

  // Web
  [LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA3]: 'User by Source (GA3)',
  [LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA4]: 'User by Source (GA4)',
  [LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA3]: 'Page Path (GA3)',
  [LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA4]: 'Page Path (GA4)',

  // Conversion
  [LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA3]: 'Goal Conversions (GA3)',
  [LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA4]: 'Goal Conversions (GA4)',

  // Not used
  [LINKTOKEN_EVENT_TYPE.RESURGE_MULTI_GA]: 'Multiple Web Sources',
  [LINKTOKEN_EVENT_TYPE.GENERIC_EVENT]: 'Generic',

  // Social
  [DETECTED_EVENT_TYPE.IM_POST_RESURGE]: 'Post Impressions Resurgence',
  [DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE]: 'Account Impressions Resurgence',
  [DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE]: 'Post Impressions',
  [DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE]: 'Impressions On Multiple Posts',
  [DETECTED_EVENT_TYPE.EG_POST_RESURGE]: 'Post Engagement Resurgence',
  [DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE]: 'Account Engagement Resurgence',
  [DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE]: 'Post Engagement',
  [DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE]: 'Engagements On Multiple Posts',
  // [DETECTED_EVENT_TYPE.FL_POST_RESURGE]: 'Post Follower Resurgence',
  // [DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE]: 'Account Follower Resurgence',
  // [DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE]: 'Post Follower',
  // [DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE]: 'Followers On Multiple Posts',

  // Web: Google Analytics
  [DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE]: 'Users by Source',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE]: 'Total Users by Source',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH]: 'Users by Page Path',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH]: 'Total Users by Page Path',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY]: 'Users by Search Term',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY]: 'Total Users by Search Term', // this is pulling in all search traffic, including organic
  [DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD]: 'Users by On-site Search Term', // on-site
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM]: 'Total Users by Search Term', // this is pulling in all search traffic, including paid
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY]:
    'Total Users by First Converting Paid Search Term',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING]: 'Users by Channel',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN]: 'Users by Campaign',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING]: 'Total Users by Channel',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME]: 'Total Users by Campaign',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD]: 'Users by Search Term',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM]: 'Total Users by On-site Search Term',
  [DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL]: 'Interactions by Event',
  [DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL]: 'Interactions by Event',

  // Web: Adobe Analytics
  [DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE]: 'Users by Source',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH]: 'Users by Page Path',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD]: 'Users by Organic Search Term',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH]: 'Users by Paid Search Term',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH]: 'Users by On-site Search Term',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL]: 'Users by Channel',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN]: 'Users by Campaign',
  [DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT]: 'Interactions by Event',
  [DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS]: 'Unique Users',

  // Web: Shortlink
  [DETECTED_EVENT_TYPE.SL_CLICKS]: 'Clicks by Shortlink',

  // Web: Google Play Store
  [DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS]: 'App Active Devices',

  // Conversion: Google Analytics
  [DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL]: 'Conversions by Event',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT]: 'Revenue by Product',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM]: 'Revenue by Source / Medium',
  [DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT]: 'Quantity by Product',
  [DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION]: 'Revenue Per Transaction',
  [DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION]: 'Transactions Per Session',
  [DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER]: 'Transactions Per Purchaser',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME]: 'Revenue by Item Name',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE]: 'Revenue by Session Source',
  [DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME]: 'Quantity by Item Name',
  [DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL]: 'Conversions by Event',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN]: 'Revenue by Campaign',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME]: 'Revenue by Campaign',

  // Conversion: Apple App Store
  [DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE]: 'App Installs',
  [DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE]: 'App Purchases by Product',
  [DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE]: 'App Revenue by Product',

  // Conversion: Google Play Store
  [DETECTED_EVENT_TYPE.GO_INSTALLS]: 'App Installs',
  [DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT]: 'App Purchases by Product',
  [DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT]: 'App Revenue by Product',
  [DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS]: 'App Uninstalls',

  // Conversion: Salesforce
  [DETECTED_EVENT_TYPE.SF_LEADS_LEADS]: 'Leads',
  [DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES]: 'Opportunities',
  [DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE]: 'Leads by Source',
  [DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM]: 'Leads by Program',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE]: 'Opportunities by Source',
  [DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE]: 'Revenue by Source',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN]:
    'Opportunities from Leads by Campaign',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN]: 'Opportunities by Campaign',

  // Conversion: NASDAQ
  [DETECTED_EVENT_TYPE.NS_FUND_FLOW]: 'Fund Flow by Ticker',

  // Conversion: Adobe Analytics
  [DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT]: 'Conversions by Event',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE]: 'Revenue by Source',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN]: 'Revenue by Campaign',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE]: 'Revenue by Fare Type',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN]: 'Transactions by Campaign',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL]: 'Transactions by Channel',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE]: 'Transactions by Fare Type',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN]: 'Quantity by Campaign',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL]: 'Quantity by Channel',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE]: 'Quantity by Fare Type',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN]: 'SkyMiles Used by Campaign',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL]: 'SkyMiles Used by Channel',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE]: 'SkyMiles Used by Fare Type',
  [DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN]: 'Bookings by Campaign',
  [DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL]: 'Bookings by Channel',
  [DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN]: 'Calls by Campaign',
  [DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL]: 'Calls by Channel',

  // Media: Social Animal
  [DETECTED_EVENT_TYPE.SA_TOTAL_SHARES]: 'Article Shares',

  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES]: 'TV Narrative Occurrences',
  [DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES]: 'Radio Narrative Occurrences',
  [DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES]:
    'Podcast Summary Narrative Occurrences',
  [DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES]:
    'Podcast Transcript Narrative Occurrences',
  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL]: 'TV Narrative Ratings (Local)',
  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL]: 'TV Narrative Ratings (National)',
  [DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS]: 'Podcast Transcript Narrative Ratings',

  // Starbucks
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE]: 'Revenue by Store',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT]: 'Revenue by Product',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM]: 'Revenue by Loyalty Program',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1]: 'Revenue by Primary Discount Code',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2]: 'Revenue by Secondary Discount Code',
  [DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE]: 'Transactions by Store',
  [DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL]: 'Transactions by Channel',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT]: 'Quantity by Product',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM]: 'Quantity by Loyalty Program',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1]: 'Quantity by Primary Discount Code',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2]: 'Quantity by Secondary Discount Code',
  [DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE]: 'Foot Traffic by State',

  [DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE]: 'Appointments by State',
  [DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT]: 'Appointments by Segment',
  [DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE]: 'Opportunities by State',
  [DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT]: 'Opportunities by Segment',
  [DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE]: 'Revenue by State',
  [DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT]: 'Revenue by Segment',
  [DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE]: 'Quantity by State',
  [DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT]: 'Quantity by Segment',

  // Third Party Media Titles aggregate different event types from MEDIA_MENTIONS_TYPES and MEDIA_RATINGS_TYPES
  MEDIA_MENTIONS: '3rd Party Media Mentions',
  MEDIA_RATINGS: '3rd Party Media Ratings'
};

// * TBD (4/15/22) - needs confirmation from product
export const DETECTOR_TYPE = Object.keys(DETECTED_EVENT_TITLE).reduce((acc, currentValue) => {
  acc[currentValue] = DETECTED_EVENT_TITLE[currentValue].toUpperCase();
  return acc;
}, {});

export const REVENUE_EVENT_TYPE = [
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME,
  DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT
];

export const TYPE_ORDER_LIST = [
  // Requested event types to the top of ordering
  DETECTED_EVENT_TYPE.NS_FUND_FLOW,
  // Putting Starbucks temporarily at the top until requested otherwise
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE,
  // REVENUE & CONVERSIONS
  // Revenue by channel
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL,
  // Revenue by campaign
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN,
  // Revenue by source
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE,
  // Revenue per transaction
  DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION,
  // Revenue by fare type
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE,
  // missing for GA4
  // Transactions per sessions
  DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION,
  DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER,
  // Transactions by channel
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL,
  // Transactions by campaign
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN,
  // Transactions by fare type
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE,
  // Revenue by product
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME,
  // Quantity by item
  DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME,
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE,
  // Quantity by channel
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL,
  // Quantity by campaign
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN,
  // SkyMiles used by channel
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL,
  // SkyMiles used by campaign
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN,
  // SkyMiles used by fare type
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE,
  // Conversions by goal
  DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT,
  // Interaction by goal
  DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT,
  // Bookings by campaign
  DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN,
  // Bookings by channel
  DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL,
  // Calls by campaign
  DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN,
  // Calls by channel
  DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL,
  // Salesforce
  DETECTED_EVENT_TYPE.SF_LEADS_LEADS,
  DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN,
  // Amplifon
  DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE,
  DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE,
  DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE,
  DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT,

  // SEARCH
  // Paid + organic
  DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY,
  DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM,
  DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH, // paid
  DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD, // organic
  // On-site
  DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM,
  DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH,
  // First converting
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY,

  // TRAFFIC
  // Users by channel
  DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL,
  // Users by campaign
  DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME,
  DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN,
  // Users by source
  DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE,
  DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE,
  // Users by page path
  DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH,
  DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH,
  // Shortlink Clicks
  DETECTED_EVENT_TYPE.SL_CLICKS,

  // 3RD PARTY MEDIA
  // TV/radio/podcast audience
  DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS,
  // Social animal shares
  DETECTED_EVENT_TYPE.SA_TOTAL_SHARES,
  // TV/radio/podcast mentions
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
  // DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  // DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
  // DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES,
  // Social animal articles
  DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,

  // Apple App Store + Google Play Store
  DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE,
  DETECTED_EVENT_TYPE.GO_INSTALLS,
  DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS,
  DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS,

  // SOCIAL
  // Single post initial
  DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE,
  DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE,
  // DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE,
  // Daily post initial
  DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE,
  DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE,
  // DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE,
  // Post resurge
  DETECTED_EVENT_TYPE.IM_POST_RESURGE,
  DETECTED_EVENT_TYPE.EG_POST_RESURGE,
  // DETECTED_EVENT_TYPE.FL_POST_RESURGE,
  // Linktoken resurge
  DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE,
  DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE
  // DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE
];

export const TYPE_ORDER_MAP = TYPE_ORDER_LIST.reduce((acc, currentValue, currentIndex) => {
  acc[currentValue] = currentIndex;

  return acc;
}, {});

export const getDrawerHeaderTitle = drawerContext => {
  if (drawerContext.isCorrelation) {
    return 'Correlation Details';
  }

  if (DETECTED_EVENT_TITLE[drawerContext.event?.type]) {
    return DETECTED_EVENT_TITLE[drawerContext.event.type];
  }

  return null;
};

export const CLICKABLE_EVENT_TYPES = [
  // Social
  DETECTED_EVENT_TYPE.IM_POST_RESURGE,
  DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE,
  DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE,
  DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE,
  DETECTED_EVENT_TYPE.EG_POST_RESURGE,
  DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE,
  DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE,
  DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE,
  // DETECTED_EVENT_TYPE.FL_POST_RESURGE,
  // DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE,
  // DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE,
  // DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE,

  // Web: Google Analytics
  DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE,
  DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH,
  DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY,
  DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY,
  DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME,
  DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD,
  DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM,
  DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL,

  // Web: Adobe Analytics
  DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE,
  DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH,
  DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD,
  DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH,
  DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH,
  DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL,
  DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT,
  DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS,

  // Web: Shortlink
  DETECTED_EVENT_TYPE.SL_CLICKS,

  // Web: Google Play Store
  DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS,

  // Conversion: Google Analytics
  DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM,
  DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION,
  DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION,
  DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE,
  DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME,
  DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING,
  DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME,

  // Conversion: Adobe Analytics
  DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL,
  DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE,
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL,
  DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE,
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL,
  DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE,
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL,
  DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE,
  DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL,
  DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL,

  // Conversion: Apple App Store
  DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE,

  // Conversion: Google Play Store
  DETECTED_EVENT_TYPE.GO_INSTALLS,
  DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS,

  // Conversion: Salesforce
  DETECTED_EVENT_TYPE.SF_LEADS_LEADS,
  DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN,

  // Media: TV Eyes
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP,
  DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE,

  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS,

  // CONVERSION: NASDAQ
  DETECTED_EVENT_TYPE.NS_FUND_FLOW,

  // Social animal
  DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,
  DETECTED_EVENT_TYPE.SA_TOTAL_SHARES,

  // Starbucks
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE,

  // Amplifon
  DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE,
  DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE,
  DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE,
  DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT,
  DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE,
  DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT
];

// Event type designations (not all lists have a functional purpose atm)
export const THIRD_PARTY_MEDIA_EVENT_TYPES = [
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP,
  DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE,
  DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,
  DETECTED_EVENT_TYPE.SA_TOTAL_SHARES
];

export const NARRATIVE_EVENT_TYPES = [
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL,
  DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL,
  DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS
];

export const APP_EVENT_TYPES = [
  DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
  DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE,
  DETECTED_EVENT_TYPE.GO_INSTALLS,
  DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS,
  DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS
];

export const SALESFORCE_EVENT_TYPES = [
  DETECTED_EVENT_TYPE.SF_LEADS_LEADS,
  DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN,
  DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN
];

export const STARBUCKS_EVENT_TYPES = [
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE,
  DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1,
  DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2,
  DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE
];

// breaking these out for the dashboard conversion pie charts.
export const MEDIA_MENTIONS_TYPES = [
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
  DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT
];

export const MEDIA_RATINGS_TYPES = [
  DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE
];

export const FILTER_TYPES = {
  social: {
    detectedEvent: [
      DETECTED_EVENT_TYPE.IM_POST_RESURGE,
      DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE,
      DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE,
      DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE,
      DETECTED_EVENT_TYPE.EG_POST_RESURGE,
      DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE,
      DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE,
      DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE
      // DETECTED_EVENT_TYPE.FL_POST_RESURGE,
      // DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE,
      // DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE,
      // DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE
    ],
    linktokenEvent: [
      LINKTOKEN_EVENT_TYPE.RESURGE_POST,
      LINKTOKEN_EVENT_TYPE.RESURGE_MULTIPOST,
      LINKTOKEN_EVENT_TYPE.INITIAL_SURGE
    ]
  },
  web: {
    detectedEvent: [
      // Web: Google Analytics
      DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE,
      DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY,
      DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY,
      DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME,
      DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM,
      DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL,
      DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL,

      // Web: Adobe Analytics
      DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE,
      DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD,
      DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL,
      DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT,

      // Web: Shortlink
      DETECTED_EVENT_TYPE.SL_CLICKS,

      // Web: Google Play Store
      DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS
    ],
    linktokenEvent: [
      LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA3,
      LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA4,
      LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA3,
      LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA4
    ]
  },
  conversion: {
    detectedEvent: [
      // Conversion: Google Analytics
      DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM,
      DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION,
      DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION,
      DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE,
      DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME,
      DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME,

      // Conversion: Adobe Analytics
      DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL,
      DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL,

      // Conversion: Apple App Store
      DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE,
      DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
      DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE,

      // Conversion: Google Play Store
      DETECTED_EVENT_TYPE.GO_INSTALLS,
      DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS,

      // Conversion: Salesforce
      DETECTED_EVENT_TYPE.SF_LEADS_LEADS,
      DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES,
      DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN,

      // Conversion: Starbucks
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
      DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE,
      DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2,
      DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE,

      // Conversion: Amplifon
      DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE,
      DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE,
      DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE,
      DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE,
      DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT
    ],
    linktokenEvent: [LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA3, LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA4]
  },
  media: {
    detectedEvent: [
      // Media: Social Animal
      DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,
      DETECTED_EVENT_TYPE.SA_TOTAL_SHARES,

      // Media: TV Eyes
      DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
      DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
      DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP,
      DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
      DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
      DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE,
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL,
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL,
      DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS
    ],
    linktokenEvent: []
  }
};

// Maps each event type to user friendly copy for attention action tables
export const DETECTED_EVENT_ATTENTION_ACTION_TITLE = {
  [DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS]: 'Radio (Mentions)',
  [DETECTED_EVENT_TYPE.BC_TV_MENTIONS]: 'TV (Mentions)',
  [DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP]: 'TV (Mentions)',
  [DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP]: 'TV (Ratings)',
  [DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS]: 'Podcast (Summary Mentions)',
  [DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE]: 'Podcast (Summary Mentions)',
  [DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS]: 'Podcast (Transcript Mentions)',
  [DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE]: 'Podcast (Transcript Mentions)',
  [DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE]: 'Podcast (Audience)',
  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES]: 'TV Narrative (Occurrences)',
  [DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES]: 'Radio Narrative (Occurrences)',
  [DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES]:
    'Podcast Summary Narrative (Occurrences)',
  [DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES]:
    'Podcast Transcript Narrative (Occurrences)',
  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL]: 'TV Narrative (Local Ratings)',
  [DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL]: 'TV Narrative (National Ratings)',
  [DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS]:
    'Podcast Transcript Narrative (Ratings)',
  [DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL]: 'Conversions',
  [DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL]: 'Interactions',
  [DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION]: 'Transactions',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT]: 'Quantity',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD]: 'Search Keywords',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH]: 'Webpage Visits',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD]: 'On-Site Search',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE]: 'Web Sources',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING]: 'Channels',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN]: 'Campaigns',
  [DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY]: 'Search Keywords',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY]:
    'Search Keywords (First Converting)',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH]: 'Webpage Visits',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM]: 'Search Keywords',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY]: 'Search Keywords',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM]: 'On-Site Search',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE]: 'Web Sources',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING]: 'Channel',
  [DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME]: 'Campaigns',
  [DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME]: 'Quantity',
  [DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL]: 'Conversions',
  [DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL]: 'Interactions',
  [DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER]: 'Transactions',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH]: 'Webpage Visits',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD]: 'Search Keywords (Organic)',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH]: 'Search Keywords (Paid)',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE]: 'Web Sources',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH]: 'On-Site Search',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN]: 'Campaigns',
  [DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL]: 'Channels',
  [DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT]: 'Conversions',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE]: 'Revenue by Source',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN]: 'Revenue by Campaign',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE]: 'Revenue by Fare Type',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN]: 'Transactions by Campaign',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL]: 'Transactions by Channel',
  [DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE]: 'Transactions by Fare Type',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN]: 'Quantity by Campaign',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL]: 'Quantity by Channel',
  [DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE]: 'Quantity by Fare Type',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN]: 'SkyMiles Used by Campaign',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL]: 'SkyMiles Used by Channel',
  [DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE]: 'SkyMiles Used by Fare Type',
  [DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT]: 'Interactions',
  [DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS]: 'Unique Users',
  [DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN]: 'Bookings by Campaign',
  [DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL]: 'Bookings by Channel',
  [DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN]: 'Calls by Campaign',
  [DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL]: 'Calls by Channel',
  [DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE]: 'Social Post (Single Post Impressions)',
  [DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE]: 'Social Post (Multi-Post Impressions)',
  [DETECTED_EVENT_TYPE.IM_POST_RESURGE]: 'Resurging Social Post (Single Post Impressions)',
  [DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE]: 'Resurging Social Post (Multi-Post Impressions)',
  [DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT]: 'Blogs / News (Mentions)',
  [DETECTED_EVENT_TYPE.SA_TOTAL_SHARES]: 'Blogs / News (Shares)',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING]: 'Revenue',
  [DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME]: 'Revenue',
  [DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE]: 'Social Post (Single Post Engagement)',
  [DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE]: 'Social Post (Multi-Post Engagement)',
  [DETECTED_EVENT_TYPE.EG_POST_RESURGE]: 'Resurging Social Post (Single Post Engagement)',
  [DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE]: 'Resurging Social Post (Multi-Post Engagement)',
  [DETECTED_EVENT_TYPE.SL_CLICKS]: 'Shortlinks',
  [DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE]: 'App Installs',
  [DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE]: 'App Purchases by Product',
  [DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE]: 'App Revenue by Product',
  [DETECTED_EVENT_TYPE.GO_INSTALLS]: 'App Installs',
  [DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT]: 'App Purchases by Product',
  [DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT]: 'App Revenue by Product',
  [DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS]: 'App Active Devices',
  [DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS]: 'App Uninstalls',
  [DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS]: 'Leads',
  [DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS]: 'Opportunities',
  [DETECTED_EVENT_TYPE.SF_LEADS_LEADS]: 'Leads',
  [DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES]: 'Opportunities',
  [DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE]: 'Leads by Source',
  [DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM]: 'Leads by Program',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE]: 'Opportunities by Source',
  [DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE]: 'Revenue by Source',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN]:
    'Opportunities from Leads by Campaign',
  [DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN]: 'Opportunities by Campaign',
  [DETECTED_EVENT_TYPE.NS_FUND_FLOW]: 'Fund Flow',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE]: 'Revenue by Store',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL]: 'Revenue by Channel',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT]: 'Revenue by Product',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM]: 'Revenue by Loyalty Program',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1]: 'Revenue by Primary Discount Code',
  [DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2]: 'Revenue by Secondary Discount Code',
  [DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE]: 'Transactions by Store',
  [DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL]: 'Transactions by Channel',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT]: 'Quantity by Product',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM]: 'Quantity by Loyalty Program',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1]: 'Quantity by Primary Discount Code',
  [DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2]: 'Quantity by Secondary Discount Code',
  [DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE]: 'Foot Traffic by State',
  [DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE]: 'Appointments by State',
  [DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT]: 'Appointments by Segment',
  [DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE]: 'Opportunities by State',
  [DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT]: 'Opportunities by Segment',
  [DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE]: 'Revenue by State',
  [DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT]: 'Revenue by Segment',
  [DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE]: 'Quantity by State',
  [DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT]: 'Quantity by Segment'
};

/**
 * Returns a user friendly copy for attention action tables by event type
 * @param {String} eventType detected event type
 * @returns String user friendly name
 */
export const getAttentionActionTitle = eventType => {
  if (DETECTED_EVENT_ATTENTION_ACTION_TITLE[eventType]) {
    return DETECTED_EVENT_ATTENTION_ACTION_TITLE[eventType];
  }

  // return the event type in the UI instead of blank
  return eventType;
};
