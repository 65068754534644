import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import setPageTitle from 'util/titles';
import InsightsTabs from './InsightsTabs';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 40
  }
});

const DashboardInsights = () => {
  const classes = useStyles();

  useEffect(() => {
    setPageTitle('Insights');
  }, []);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <InsightsTabs />
    </Grid>
  );
};

DashboardInsights.propTypes = {};

export default DashboardInsights;
