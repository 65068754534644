import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_ANNOTATION_FOR_CONTAINER, UPDATE_ANNOTATION } from 'gql/annotation';
import moment from 'moment';
import Box from 'components/Box';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import { showToast } from 'contexts/ToastContext';
import { goToRoute } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';
import { AnnotationsPath } from 'util/paths';
import modalStyles from './modalStyles';

const styles = makeStyles({
  ...modalStyles,
  error: {
    color: '#E81828'
  },
  textfield: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  seeAll: {
    marginLeft: '0px !important'
  },
  delete: {
    color: '#FFFFFF',
    background: '#E81828',
    marginLeft: '0px !important',
    '&:hover': {
      background: '#979797'
    }
  }
});

const refetchQueries = ['annotationsForContainer', 'paginatedAnnotations'];

const CreateAnnotationModal = props => {
  const {
    selectedAnnotation,
    selectedDates: defaultSelectedDates,
    isModalOpen,
    onChange,
    history,
    hideSeeAllButton,
    openDeleteModal
  } = props;

  const [selectedDates, setSelectedDates] = useState(defaultSelectedDates);
  const [annotationText, setAnnotationText] = useState('');
  const [amplitude, setAmplitude] = useState(null);

  const classes = styles();

  useEffect(() => {
    ValidatorForm.addValidationRule('isMaxLength', value => value.length <= 255);
  }, []);

  const [
    createAnnotation,
    { loading: createLoading, error: createError, data: createData }
  ] = useMutation(CREATE_ANNOTATION_FOR_CONTAINER, { refetchQueries });

  const [
    updateAnnotation,
    { loading: updateLoading, error: updateError, data: updateData }
  ] = useMutation(UPDATE_ANNOTATION, { refetchQueries });

  useEffect(() => {
    if (selectedAnnotation) {
      setSelectedDates({ start: selectedAnnotation.start_date, end: selectedAnnotation.end_date });
      setAnnotationText(selectedAnnotation.message);
      setAmplitude(selectedAnnotation.amplitude);
    }
  }, [selectedAnnotation]);

  useEffect(() => {
    if (createData?.createAnnotationForContainer) {
      showToast('Annotation created.', 'success');
      setAnnotationText('');
      onChange({ isModalOpen: false });
    }
  }, [createData]);

  useEffect(() => {
    if (updateData?.updateAnnotation) {
      showToast('Annotation updated.', 'success');
      setAnnotationText('');
      onChange({ isModalOpen: false });
    }
  }, [updateData]);

  useEffect(() => {
    if (createError || updateError) {
      handleGraphQLError(createError || updateError);
    }
  }, [createError, updateError]);

  const closeModal = () => {
    setAnnotationText('');
    onChange({ isModalOpen: false });
  };

  const handleRouting = () => {
    // util routing method that pushes to the history object with additional logic.
    goToRoute(AnnotationsPath, history);
  };

  const startDate = moment.utc(selectedDates.start).startOf('d');
  const endDate = moment.utc(selectedDates.end).startOf('d');

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <ValidatorForm
          onSubmit={() => {
            if (selectedAnnotation == null) {
              createAnnotation({
                variables: {
                  startDate,
                  endDate,
                  message: annotationText,
                  amplitude: parseInt(amplitude, 10)
                }
              });
            } else {
              updateAnnotation({
                variables: {
                  id: selectedAnnotation.id,
                  startDate,
                  endDate,
                  message: annotationText,
                  amplitude: parseInt(amplitude, 10)
                }
              });
            }
          }}
        >
          <Box p={12}>
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>
                  {`${selectedAnnotation == null ? 'Create' : 'Edit'} Annotation`}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography className={classes.label}>Select Date(s)</Typography>
              </Grid>
              <AnalyticsDatePicker
                setDateRange={setSelectedDates}
                calRange={selectedDates}
                hideRangeButtons
                delta
                allowStartAfterCurrentDate
              />
              <Grid className={classes.textfield} item container style={{ marginTop: '24px' }}>
                <Grid item style={{ flex: 1, marginRight: '24px' }}>
                  <Grid item container>
                    <Typography className={classes.label}>Annotation Text</Typography>
                    <Typography className={classes.required}>(Required)</Typography>
                  </Grid>
                  <TextValidator
                    autoFocus
                    fullWidth
                    variant="filled"
                    placeholder="Enter Annotation Text"
                    value={annotationText}
                    onChange={event => setAnnotationText(event.target.value)}
                    validators={['required', 'isMaxLength']}
                    errorMessages={[
                      '* Required',
                      `Must not exceed 255 characters in length. Current length: ${annotationText.length}`
                    ]}
                  />
                </Grid>
                <Grid item style={{ flex: 0 }}>
                  <Grid item container>
                    <Typography className={classes.label}>Amplitude</Typography>
                  </Grid>
                  <TextValidator
                    fullWidth
                    type="number"
                    variant="filled"
                    placeholder="#"
                    value={amplitude != null ? amplitude : ''}
                    onChange={event => setAmplitude(event.target.value)}
                    validators={['matchRegexp:^[0-9]+$', 'minNumber:0']}
                    errorMessages={['Value must be positive', 'Value must be positive']}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" className={classes.actions}>
                <span>
                  {selectedAnnotation && (
                    <Button className={classes.delete} variant="outlined" onClick={openDeleteModal}>
                      Delete
                    </Button>
                  )}
                  {!hideSeeAllButton && !selectedAnnotation && (
                    <Button className={classes.seeAll} variant="outlined" onClick={handleRouting}>
                      See All Annotations
                    </Button>
                  )}
                </span>
                <span>
                  <Button variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={createLoading || updateLoading}
                  >
                    {`${selectedAnnotation == null ? 'Create' : 'Save'}`}
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

CreateAnnotationModal.propTypes = {
  selectedAnnotation: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    message: PropTypes.string,
    amplitude: PropTypes.number
  }),
  selectedDates: PropTypes.shape(),
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  hideSeeAllButton: PropTypes.bool,
  openDeleteModal: PropTypes.func
};

CreateAnnotationModal.defaultProps = {
  selectedAnnotation: null,
  selectedDates: {
    start: new Date(
      moment()
        .utc()
        .subtract(1, 'd')
    ),
    end: new Date(
      moment()
        .utc()
        .subtract(1, 'd')
    )
  },
  hideSeeAllButton: false,
  openDeleteModal: () => {}
};

export default withRouter(CreateAnnotationModal);
