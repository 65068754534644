/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CAUSAL_CHAINS = gql`
  query causalChains($sortField: String) {
    causalChains(sortField: $sortField) {
      id
      title
      summary
      justification
      feedback: prompt_template_feedback_from_user {
        feedback_type
      }
      revenue
      start_date
      end_date
    }
  }
`;
