const SIMPLE_SOCIAL_TYPE_ENUM = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  INSTAGRAM_STORY: 'instagram_story',
  INSTAGRAM_REEL: 'instagram_reel',
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  GOOGLE: 'google',
  GOOGLE_ANALYTICS: 'google_analytics',
  GOOGLE_WEB: 'google_web',
  GOOGLE_VIEW: 'google_view',
  CORRELATION: 'correlation',
  SOCIAL_ANIMAL: 'social_animal',
  TV: 'tv',
  RADIO: 'radio',
  PODCAST: 'podcast',
  BROADCAST: 'broadcast',
  ADOBE_ANALYTICS: 'adobe_analytics',
  APPLE_APP_STORE_CONNECT: 'apple_app_store_connect',
  GOOGLE_PLAY_CONSOLE: 'google_play_console',
  APP_STORE_CONNECT_APP: 'app_store_connect_app',
  GOOGLE_PLAY_CONSOLE_APP: 'google_play_console_app',
  NASDAQ: 'nasdaq',
  SHORTLINK: 'shortlink',
  SALESFORCE: 'salesforce',
  STARBUCKS: 'starbucks',
  STARBUCKS_PLACER: 'starbucks_placer',
  AMPLIFON: 'amplifon'
};

export default SIMPLE_SOCIAL_TYPE_ENUM;
