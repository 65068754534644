import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goToRoute } from 'util/routesHelpers';
import { makeStyles } from '@material-ui/core/styles';
import RadioDropdown from 'components/AnalyticsSalesforce/RadioDropdown';
import moment from 'moment';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  // Tabs,
  // Tab,
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import WEB_AND_APPS_GOALS from 'gql/webAndAppsGoal';
import { WEB_LINKED_ACCOUNTS, SORTED_WEB_LINKED_ACCOUNTS } from 'gql/linktoken';
import handleGraphQLError from 'util/error';
import { useQuery, useLazyQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import colors from 'util/colors';
import { AlbTooltip } from 'components/AlbTooltip';
import AlbSwitch from 'components/AlbSwitch';
import Box from 'components/Box';
import tooltipText from 'util/tooltipText';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import { AdobeAnalyticsManagePredictionEventsPath } from 'util/paths';
import isFeatureVisible from 'util/isFeatureVisible';
import { FEATURE_FLAG } from 'util/featureFlags';
import RESPONSE_TYPE_ENUM from 'util/responseTypeEnum';
import { UpdateConversionEventNamePersistence } from 'components/MutationContainers';
import ForecastGraphToolTipDetectedEvents from './ForecastGraphTooltipDetectedEvents';
import { dimensionOptions, opportunityOptions } from '../AnalyticsSalesforce/SalesforceConsts';

const useStyles = makeStyles({
  root: {
    marginTop: 50
  },
  emptyGoalsContainer: {
    width: 1178,
    height: 500
  },
  heading: {
    padding: '0 0 26px 0'
  },
  headingCopy: {
    fontSize: 24,
    fontWeight: 500,
    color: '#0A1734'
  },
  goalName: {
    fontWeight: 600,
    fontSize: 18,
    color: '#0D1A3A',
    wordBreak: 'break-word'
  },
  forecast: {
    fontWeight: 500,
    fontSize: 30,
    color: '#32327D'
  },
  striped: {
    backgroundColor: 'rgba(151, 151, 151, 0.1)'
  },
  tabs: {
    flexWrap: 'wrap'
  },
  actual: {
    height: 10,
    width: 10,
    backgroundColor: '#979797',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10
  },
  trend: {
    width: 16,
    borderTop: 'solid 3px #32327D',
    marginRight: 10
  },
  legend: {
    alignSelf: 'stretch'
  },
  legendCopy: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: '18px',
    color: '#6F6F6F'
  },
  tableContainer: {
    overflow: 'visible',
    marginBottom: 50
  },
  switchLabel: {
    fontSize: '13px',
    lineHeight: '20px'
  },
  manageButton: {
    paddingLeft: 30
  },
  dropdowns: {
    display: 'flex',
    paddingTop: '10px'
  },
  linktokenDropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    minHeight: '64px'
  },
  linktokenDropdown: {
    minWidth: '50%',

    '& > div': {
      height: '22px'
    }
  }
});

const ForecastGraph = ({
  accounts,
  hideTitle,
  history,
  featureFlags,
  reportTitle,
  chartTitle,
  emptyPredictions,
  metric,
  yAxesLabel,
  xAxesLabel,
  dashboard
}) => {
  const classes = useStyles();

  // account types for tabs
  const ACCOUNT_TYPES = [
    LINKTOKEN_TYPE_ENUM.GOOGLE_VIEW,
    LINKTOKEN_TYPE_ENUM.GOOGLE_WEBPROPERTY_G4,
    LINKTOKEN_TYPE_ENUM.AA_SEGMENT,
    LINKTOKEN_TYPE_ENUM.SALESFORCE
  ];

  // reference each chart rendered
  const chartRef = useRef([]);

  // reference each goal rendered
  const goalRef = useRef(null);

  const [dataSets, setDataSets] = useState([]); // data sets before processing
  const [chartData, setChartData] = useState({}); // chart data after data sets is processed for ChartJS
  const [goals, setGoals] = useState([]); // list of goals
  const [tabs, setTabs] = useState([]); // list of tokens filtered by ACCOUNT_TYPES to render tabs
  const [currentTab, setCurrentTab] = useState(0); // active tab index
  const [currentTokenId, setCurrentTokenId] = useState(null); // active tab's token id
  const [openToolTip, setOpenToolTip] = useState(false); // show or hide the tooltip
  const [tooltipData, setTooltipData] = useState(null);
  const [goalIndex, setGoalIndex] = useState(null); // mouse is hovering over a specific row in the table of goals
  const [detectedEventsDate, setDetectedEventsDate] = useState(null); // date as string to fetch detected events around it
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false); // mouse is hovering over the flyout tooltip
  const [isMouseOverDataPoint, setIsMouseOverDataPoint] = useState(false); // mouse is hovering over a data point on the chart
  const [isTopPredictionsChecked, setIsTopPredictionsChecked] = useState(false);

  // specific for salesforce
  const [dimension, setDimension] = useState({ value: 'type', label: 'Type' });
  const [opportunity, setOpportunity] = useState({ value: null, label: 'All' });

  // returns boolean if salesforce tab is active
  const isSalesforceTabActive = () => {
    return tabs?.[currentTab]?.type === LINKTOKEN_TYPE_ENUM.SALESFORCE;
  };

  // conditional for salesforce deal size and volume graph
  const yhatGraphs = ['salesforceDealSize', 'salesforceVolume'].includes(metric);

  /**
   * Types casts the forecast number as a % change string with +/-
   * @param {Number} forecast % change as an integer
   * @returns {String} formatted % change string with +/-
   */
  const formatForecast = forecast => {
    if (forecast > 0) {
      return `+${forecast}`;
    }
    return `${forecast}`;
  };

  /**
   * Returns an object for rendering the HTML table
   * @param {Number} id Goal id
   * @param {String} goalName Name of the goal
   * @param {Object} chart ChartJS object
   * @param {String} foreCast30 30 day forecasted lift/loss as a percentage
   * @param {String} foreCast60 60 day forecasted lift/loss as a percentage
   * @param {String} foreCast90 90 day forecasted lift/loss as a percentage
   * @param {Boolean} showChart A flag to show or hide the forecasting chart
   * @returns {Object} Object for rendering the HTML table
   */
  const createData = (id, goalName, chart, foreCast30, foreCast60, foreCast90, showChart) => {
    const roundedForeCast30 = Math.round(foreCast30);
    const roundedForeCast60 = Math.round(foreCast60);
    const roundedForeCast90 = Math.round(foreCast90);
    return {
      id,
      goalName,
      chart,
      foreCast30: formatForecast(roundedForeCast30),
      foreCast60: formatForecast(roundedForeCast60),
      foreCast90: formatForecast(roundedForeCast90),
      showChart
    };
  };

  // mouse hover over a table, we need to update the chartRef current state
  useEffect(() => {
    chartRef.current = chartRef.current.slice(0, chartData.length);
  }, [chartData]);

  // mouse hover over a table, we need to update the goalRef current state
  useEffect(() => {
    goalRef.current = goalIndex;
  }, [goalIndex]);

  /**
   * Show or hide tooltip if cursor is hovering over a data point and/or tooltip
   *
   * @param {Boolean} forceCloseTooltip - closes the tooltip
   */
  const recheckCloseTooltip = async (forceCloseTooltip = false) => {
    if (forceCloseTooltip === true) {
      setIsMouseOverTooltip(false);
      setOpenToolTip(false);
      setTooltipData(null);
      setDetectedEventsDate(null);
    } else if (!isMouseOverTooltip && !isMouseOverDataPoint) {
      setOpenToolTip(false);
      setTooltipData(null);
      setDetectedEventsDate(null);
    }
  };

  // general configuration for each graph
  const graphOptions = {
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ...(yAxesLabel && {
            scaleLabel: {
              // Use scaleLabel instead of title for Chart.js 2.x
              display: true,
              labelString: yAxesLabel // Use labelString instead of text for Chart.js 2.x
            }
          }),
          gridLines: {
            color: colors.gray,
            display: true
          },
          ticks: {
            precision: 2,
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            callback: value => value.toLocaleString()
          }
        }
      ],
      xAxes: [
        {
          ...(xAxesLabel && {
            scaleLabel: {
              // Use scaleLabel instead of title for Chart.js 2.x
              display: true,
              labelString: xAxesLabel // Use labelString instead of text for Chart.js 2.x
            }
          }),
          gridLines: {
            color: colors.gray,
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600'
          }
        }
      ]
    }
  };

  /**
   * Normalizes the raw data response from API for ChartJS, then updates the component state
   */
  const prepareDataSets = () => {
    let postProcessDataSets = [];
    let datasets = [];

    if (dataSets.length) {
      postProcessDataSets = dataSets?.[0].goalRow.map(goal => {
        if (isSalesforceTabActive()) {
          datasets = [
            {
              type: 'line',
              label: 'Yhat',
              fill: false,
              pointRadius: 0,
              borderWidth: 2,
              data: goal?.dataset?.yhat.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderColor: '#32327D'
            },
            {
              type: 'line',
              label: 'Yhat Upper',
              fill: 2,
              backgroundColor: '#cce2ee',
              pointRadius: 0,
              borderWidth: 2,
              data: goal?.dataset?.yhatUpper.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderColor: '#afd0e4'
            },
            {
              type: 'line',
              label: 'Yhat Lower',
              fill: false,
              pointRadius: 0,
              borderWidth: 2,
              data: goal?.dataset?.yhatLower.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderColor: '#afd0e4'
            },

            // prediction datasets
            {
              type: 'line',
              label: 'Yhat Prediction',
              fill: false,
              data: goal?.dataset?.yhatForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),

              borderColor: '#32327D',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'line',
              label: 'Yhat Prediction Upper',
              fill: 5,
              backgroundColor: '#cce2ee',
              data: goal?.dataset?.yhatUpperForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),

              borderColor: '#afd0e4',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'line',
              label: 'Yhat Prediction Lower',
              fill: false,
              data: goal?.dataset?.yhatLowerForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),

              borderColor: '#afd0e4',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'scatter',
              label: 'Actual',
              borderColor: '#979797',
              backgroundColor: '#979797',
              pointRadius: 1,
              data: goal?.dataset?.value.map(element => element.y)
            }
          ];
        } else {
          datasets = [
            {
              type: 'line',
              label: 'Trend',
              fill: false,
              pointRadius: 0,
              borderWidth: 2,
              data: goal?.dataset?.trend.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderColor: '#32327D'
            },
            {
              type: 'line',
              label: 'Trend Prediction',
              fill: false,
              data: goal?.dataset?.trendForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderDash: [10, 5],
              borderColor: '#32327D',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'line',
              label: 'Trend Prediction Upper',
              fill: false,
              data: goal?.dataset?.trendUpperForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderDash: [10, 5],
              borderColor: '#00A84F',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'line',
              label: 'Trend Prediction Lower',
              fill: false,
              data: goal?.dataset?.trendLowerForecast.map(element =>
                element.y ? element.y.toFixed(2) : element.y
              ),
              borderDash: [10, 5],
              borderColor: '#EA4335',
              pointRadius: 0,
              borderWidth: 2
            },
            {
              type: 'scatter',
              label: 'Actual',
              borderColor: '#979797',
              backgroundColor: '#979797',
              pointRadius: 2,
              data: goal?.dataset?.value.map(element => element.y)
            }
          ];
        }

        return {
          goalId: goal?.id,
          goalName: goal?.name,
          thirtyDayForecast: goal?.thirtyDayForecast,
          sixtyDayForecast: goal?.sixtyDayForecast,
          ninetyDayForecast: goal?.ninetyDayForecast,
          goalChangePoint: goal?.goalChangePoint.map(element => element.x),
          showChart: goal?.dataset?.trend.some(element => element.y != null && element.y > 0),
          labels: goal?.dataset?.trend.map(element =>
            moment(element.x)
              .utc()
              .format(isSalesforceTabActive() ? 'MM/D/YY' : 'MMM D')
          ),
          date: goal?.dataset?.trend.map(element =>
            moment(element.x)
              .utc()
              .format()
          ),
          datasets
        };
      });
    }

    setChartData(postProcessDataSets);
  };

  /**
   * Updates each goal, annotating (vertical dash green line) for goal change point
   */
  const updateGoals = () => {
    if (chartData.length) {
      const GOALS = chartData.map((goal, i) => {
        // for each goal, add the annotation configuration
        const annotations = goal.goalChangePoint.map(annotation => {
          return {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: moment(annotation)
              .utc()
              .format(isSalesforceTabActive() ? 'MM/D/YY' : 'MMM D'),
            borderDash: [10, 5],
            borderColor: '#00A84F',
            label: {
              enabled: true,
              position: 'top'
            },
            onMouseenter: context => {
              setDetectedEventsDate(annotation);

              // prepare to pass this state down into the tooltip
              setTooltipData({ top: `${context.y}px`, left: `${context.x}px` });

              // show the tooltip
              setOpenToolTip(true);

              // mouse is actively hovering over a annotated data point
              setIsMouseOverDataPoint(true);
            },
            onMouseleave: () => {
              setIsMouseOverDataPoint(false);
              recheckCloseTooltip(true);
            }
          };
        });

        annotations.push({
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: moment()
            .utc()
            .format(isSalesforceTabActive() ? 'MM/D/YY' : 'MMM D'),
          borderColor: '#000000',
          label: {
            content: 'Today',
            enabled: true,
            position: 'top',
            backgroundColor: 'transparent',
            fontColor: '#0D1A3A',
            fontFamily: 'Poppins',
            xAdjust: isSalesforceTabActive() ? 25 : -25
          }
        });

        return createData(
          goal.goalId,
          goal.goalName,
          <Line
            type="line"
            data={goal}
            key={goal.goalId}
            // eslint-disable-next-line no-return-assign
            ref={el => (chartRef.current[i] = el)}
            options={{
              ...graphOptions,

              annotation: {
                events: ['mouseenter', 'mouseleave'],
                annotations
              },
              hover: {
                mode: 'nearest',
                intersect: true
              },
              tooltips: {
                enabled: false
              }
            }}
          />,
          goal.thirtyDayForecast,
          goal.sixtyDayForecast,
          goal.ninetyDayForecast,
          goal.showChart
        );
      });

      // each goal is ready to render in HTML table
      setGoals(GOALS);
    } else {
      setGoals([]);
    }
  };

  const { loading, error, data, refetch } = useQuery(WEB_AND_APPS_GOALS, {
    variables: {
      linkTokens: [currentTokenId],
      isPersistent: isTopPredictionsChecked !== true,
      opportunityFromLead: opportunity.value,
      dimension: dimension.value,
      metric
    },
    fetchPolicy: 'network-only',
    skip: currentTokenId == null
  });

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const ACCOUNTS_QUERY = dashboard ? SORTED_WEB_LINKED_ACCOUNTS : WEB_LINKED_ACCOUNTS;

  const [
    getWebLinkedAccounts,
    { data: dataWebLinkedAccounts, error: errorWebLinkedAccounts }
  ] = useLazyQuery(ACCOUNTS_QUERY, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (errorWebLinkedAccounts) {
      handleGraphQLError(errorWebLinkedAccounts);
    }
  }, [errorWebLinkedAccounts]);

  /**
   * Filters accounts for GA types
   * @param {array} accountsToFilter array of link tokens
   */
  const filterAccounts = accountsToFilter => {
    return accountsToFilter
      .filter(item => ACCOUNT_TYPES.includes(item?.type))
      .map(tab => {
        return {
          id: tab.id,
          title: tab.remote_name,
          type: tab.type
        };
      });
  };

  useEffect(() => {
    if (accounts == null) {
      getWebLinkedAccounts();
    } else if (accounts.length > 0) {
      const filteredAccounts = filterAccounts(accounts);
      if (filteredAccounts.length > 0) {
        setCurrentTab(0);
        setTabs(filteredAccounts);
        setCurrentTokenId(filteredAccounts[0].id);
      }
    }
  }, [accounts]);

  // filter linked accounts for the tabs
  useEffect(() => {
    const query = dashboard ? 'webLinkedAccountsByGoal' : 'webLinkedAccounts';

    if (dataWebLinkedAccounts?.[query]?.length > 0) {
      const filteredAccounts = filterAccounts(dataWebLinkedAccounts?.[query]);
      if (filteredAccounts.length > 0) {
        setCurrentTab(0);
        setTabs(filteredAccounts);
        setCurrentTokenId(filteredAccounts[0].id);
      }
    }

    if (accounts == null && dataWebLinkedAccounts?.[query]?.length === 0) {
      setTabs([]);
      setCurrentTokenId(null);
    }
  }, [dataWebLinkedAccounts]);

  // update the current linktoken id of the active tab
  useEffect(() => {
    if (tabs?.[currentTab]?.id) {
      setCurrentTokenId(tabs[currentTab].id);
    }
  }, [currentTab]);

  useEffect(() => {
    if (data?.webAndAppsGoal) {
      setDataSets(data?.webAndAppsGoal);
    } else {
      setDataSets([]);
    }
  }, [data]);

  useEffect(() => {
    prepareDataSets();
  }, [dataSets]);

  useEffect(() => {
    updateGoals();
  }, [chartData]);

  const ManageAAConversionButton = () =>
    isFeatureVisible(featureFlags, FEATURE_FLAG.ADOBE_ANALYTICS_CONVERSION_PREDICTION) && (
      <Box className={classes.manageButton}>
        <Button
          onClick={() => goToRoute(AdobeAnalyticsManagePredictionEventsPath, history)}
          variant="contained"
          color="primary"
        >
          Manage Predictions
        </Button>
      </Box>
    );

  return (
    <Box className={!hideTitle ? classes.root : ''}>
      {!hideTitle ? (
        <Box className={classes.heading}>
          <Typography className={classes.headingCopy}>Conversions by Goal</Typography>
        </Box>
      ) : (
        <></>
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        {isSalesforceTabActive() && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            item
            className={classes.dropdowns}
          >
            <RadioDropdown
              options={opportunityOptions}
              selectedOption={opportunity}
              setSelectedOption={setOpportunity}
            />
            <RadioDropdown
              options={dimensionOptions}
              selectedOption={dimension}
              setSelectedOption={setDimension}
            />
          </Grid>
        )}

        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          {tabs.length > 0 && (
            <Grid className={classes.linktokenDropdownContainer} item xs={9}>
              <Select
                className={classes.linktokenDropdown}
                variant="filled"
                value={currentTab}
                onChange={e => {
                  setCurrentTab(e.target.value);
                }}
              >
                {tabs.map(({ id, title }, i) => (
                  <MenuItem key={id} value={i}>
                    <Typography>{title}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {/* <Grid item xs={9}>
            <Tabs
              indicatorColor="primary"
              value={currentTab}
              onChange={(e, value) => {
                setCurrentTab(value);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map(tab => {
                return (
                  <Tab
                    className={classes.tab}
                    key={tab.id}
                    label={
                      <Grid container justifyContent="center">
                        <Typography>{tab.title}</Typography>
                      </Grid>
                    }
                  />
                );
              })}
            </Tabs>
          </Grid> */}
          <Grid
            item
            container
            xs={3}
            justifyContent="center"
            alignItems="center"
            className={classes.legend}
          >
            <Box display="flex" alignItems="center" pr={15}>
              <div className={classes.actual} />
              <Typography className={classes.legendCopy}>Actual</Typography>
            </Box>
            <Box display="flex" alignItems="center" pl={15} pr={15}>
              <div className={classes.trend} />
              <Typography className={classes.legendCopy}>
                {yhatGraphs ? 'Forecast' : 'Trend'}
              </Typography>
            </Box>
            <Box display="flex">
              <AlbTooltip
                title={yhatGraphs ? tooltipText.forecast : tooltipText.goalPrediction}
                placement="top"
              />
            </Box>
          </Grid>
        </Grid>

        {tabs?.[currentTab]?.type === LINKTOKEN_TYPE_ENUM.AA_SEGMENT && (
          <Box px={24}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
                Tracked Predictions
              </Typography>
              <AlbSwitch
                onChange={event => setIsTopPredictionsChecked(event.target.checked)}
                checked={isTopPredictionsChecked}
              />
              <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
                Trending Predictions
              </Typography>
              <ManageAAConversionButton />
            </Grid>
          </Box>
        )}

        {loading && (
          <Box className={classes.loadingEventsContainer}>
            <AlbLoading />
          </Box>
        )}
        {!loading && (
          <>
            <Table>
              <colgroup>
                <col width="20%" />
                <col width="35%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>{reportTitle}</TableCell>
                  <TableCell>{chartTitle}</TableCell>
                  <TableCell align="center">30 Day Forecast</TableCell>
                  <TableCell align="center">60 Day Forecast</TableCell>
                  <TableCell align="center">90 Day Forecast</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goals?.length ? (
                  goals.map((row, i) => (
                    <TableRow key={row.id} onMouseEnter={() => setGoalIndex(i)}>
                      <TableCell component="th" scope="row">
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <Typography className={classes.goalName}>{row.goalName}</Typography>
                          {tabs?.[currentTab]?.type === LINKTOKEN_TYPE_ENUM.AA_SEGMENT &&
                            isTopPredictionsChecked && (
                              <Box display="flex" justifyContent="center" mt={8}>
                                <UpdateConversionEventNamePersistence
                                  mutationVariables={{
                                    id: row.id,
                                    isPersistent: isTopPredictionsChecked
                                  }}
                                  buttonText="Track Prediction"
                                  onChange={event => {
                                    if (event.responseType === RESPONSE_TYPE_ENUM.OK) {
                                      refetch();
                                    }
                                  }}
                                />
                              </Box>
                            )}
                        </Box>
                      </TableCell>

                      <TableCell>
                        {i === goalIndex && openToolTip ? (
                          <Box
                            onMouseEnter={() => setIsMouseOverTooltip(true)}
                            onMouseLeave={() => {
                              setIsMouseOverTooltip(false);
                              recheckCloseTooltip(true);
                            }}
                          >
                            <ForecastGraphToolTipDetectedEvents
                              openToolTip
                              detectedEventsDate={detectedEventsDate}
                              toolTipData={tooltipData}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                        {row.showChart ? (
                          row.chart
                        ) : (
                          <Typography>
                            Not Enough Recent Data - No Goal Data Within 180 Days
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="center" className={classes.striped}>
                        <Typography className={classes.forecast}>{row.foreCast30}%</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.forecast}>{row.foreCast60}%</Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.striped}>
                        <Typography className={classes.forecast}> {row.foreCast90}%</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.emptyGoalsContainer}>
                    <TableCell colSpan={4}>
                      <Box>
                        <Typography align="center">{emptyPredictions}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
    </Box>
  );
};

ForecastGraph.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()),
  hideTitle: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  reportTitle: PropTypes.string,
  chartTitle: PropTypes.string,
  emptyPredictions: PropTypes.string,
  metric: PropTypes.string,
  yAxesLabel: PropTypes.string,
  xAxesLabel: PropTypes.string,
  dashboard: PropTypes.bool
};

ForecastGraph.defaultProps = {
  accounts: null,
  hideTitle: false,
  reportTitle: 'Report Name',
  chartTitle: '6 Months History with Added 90 Day Forecast',
  emptyPredictions: 'There are no goals to display at this time.',
  metric: 'webAndAppsGoal',
  yAxesLabel: null,
  xAxesLabel: null,
  dashboard: false
};

const mapStateToProps = state => {
  return {
    featureFlags: state.auth.featureFlags
  };
};

export default withRouter(connect(mapStateToProps)(ForecastGraph));
