import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';

import { showToast } from 'contexts/ToastContext';
import { ORGANIZATION_SETTINGS, UPDATE_ORGANIZATION_DATE_PREFERENCES } from 'gql/organization';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';

const styles = makeStyles({
  inputGroup: {
    alignItems: 'center',
    '& .MuiSelect-selectMenu': {
      lineHeight: '1'
    }
  }
});

const CalendarSettings = () => {
  const [weekStart, setWeekStart] = useState(0);
  const [yearStart, setYearStart] = useState(1);

  const classes = styles();

  const weekdays = moment.weekdays();
  const months = moment.months().map((month, i) => ({
    index: i + 1,
    month
  }));

  const { data, loading, error: queryError } = useQuery(ORGANIZATION_SETTINGS, {
    fetchPolicy: 'network-only'
  });

  const [updateDatePreferences] = useMutation(UPDATE_ORGANIZATION_DATE_PREFERENCES);

  useEffect(() => {
    if (data?.getCurrentOrganization) {
      const { week_start: startDay, year_start: startMonth } = data.getCurrentOrganization;

      setWeekStart(startDay);
      setYearStart(startMonth);
    }
  }, [data]);

  const handleSelectWeekStart = event => {
    setWeekStart(event.target.value);

    updateDatePreferences({ variables: { week_start: event.target.value } })
      .then(response => {
        if (response?.data?.updateDatePreferences)
          showToast('Successfully set week start date.', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  const handleSelectYearStart = event => {
    setYearStart(event.target.value);

    updateDatePreferences({ variables: { year_start: event.target.value } })
      .then(response => {
        if (response?.data?.updateDatePreferences)
          showToast('Successfully set year start month.', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  if (loading) return <AlbLoading />;
  if (queryError) return <p>{queryError.message}</p>;

  return (
    <Box p={30}>
      <ValidatorForm
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Grid container direction="column" spacing={4}>
          <Grid container item xs={12} md={6} lg={4} className={classes.inputGroup}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography gutterBottom>Week Start Day</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <SelectValidator
                value={weekStart}
                onChange={handleSelectWeekStart}
                variant="outlined"
                fullWidth
              >
                {weekdays.map((weekday, key) => (
                  <MenuItem key={weekday} value={key}>
                    {weekday}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={4} className={classes.inputGroup}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography gutterBottom>Fiscal Year Start Month</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <SelectValidator
                value={yearStart}
                onChange={handleSelectYearStart}
                variant="outlined"
                fullWidth
              >
                {months.map(({ month, index }) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Box>
  );
};

export default CalendarSettings;
