import React from 'react';
import { Grid } from '@material-ui/core';
import ForecastGraph from 'components/ForecastGraph';

const DashboardPredictionsV4 = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ForecastGraph hideTitle dashboard />
      </Grid>
    </Grid>
  );
};

export default DashboardPredictionsV4;
