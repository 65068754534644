import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { CAUSAL_CHAINS_SUMMARY } from 'gql/causalChainsSummary';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import StayTuned from './StayTuned';

import DateView from './DateView';
import SummaryOfSummaries from './SummaryOfSummaries';

const useStyles = makeStyles(() => ({
  title: {
    lineHeight: '1.2',
    marginBottom: '40px'
  }
}));

const InsightsTabPanel = props => {
  const { timeSlice } = props;
  const [offset, setOffset] = useState(0);
  const classes = useStyles();

  const { loading, error, data } = useQuery(CAUSAL_CHAINS_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: {
      timeSlice,
      offset
    }
  });

  const handleOffsetChange = change => {
    setOffset(prevOffset => (prevOffset + change < 0 ? 0 : prevOffset + change));
  };

  if (loading) {
    return <AlbLoading />;
  }

  if (error) {
    return <AlbError error={error} toast={false} />;
  }

  if (!loading && !data?.causalChainsSummary[0]?.summary) {
    return (
      <>
        <DateView
          timeSlice={timeSlice}
          data={data}
          offset={offset}
          handleOffsetChange={handleOffsetChange}
        />
        <h1 className={classes.title}>
          {data?.causalChainsSummary[0]?.time_period?.period_type || ''} Intelligence Briefing
        </h1>
        <StayTuned />;
      </>
    );
  }

  return (
    <>
      <DateView
        timeSlice={timeSlice}
        data={data}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
      <h1 className={classes.title}>
        {data?.causalChainsSummary[0]?.time_period?.period_type || ''} Intelligence Briefing
      </h1>
      <SummaryOfSummaries summary={data?.causalChainsSummary[0]?.summary} />
    </>
  );
};

InsightsTabPanel.propTypes = {
  timeSlice: PropTypes.string.isRequired
};

export default InsightsTabPanel;
