/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlembicTitleChip from 'components/AlembicTitleChip';
import { getAttentionActionTitle } from 'util/detectedEvents';
import CausalTimeline from './CausalTimeline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  headingNumber: {
    fontWeight: 'bold'
  },
  accordion: {
    marginBottom: '30px'
  },
  footerButton: {
    margin: '1em 0',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '-10px',

    '& > *': {
      marginRight: '10px',
      marginBottom: '10px'
    }
  }
}));

/**
 * @method
 * @summary This component renders the accordion
 * @name CausalAccordion
 * @return {Object} - React JSX
 */

const CausalAccordion = props => {
  const { justifyData, expandJustify } = props;
  const classes = useStyles();

  const countNodes = causalChain => {
    const nodeCount = Object.keys(causalChain).length;
    return nodeCount > 1 ? (
      <span style={{ fontWeight: 700 }}>{`${nodeCount} Nodes`}</span>
    ) : (
      'Single Node Chain'
    );
  };

  return (
    <div className={classes.root}>
      {justifyData.map((causalChain, i) => (
        <Accordion key={causalChain.id} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container className={classes.heading} direction="column">
              <div className={classes.title}>
                <span>
                  <span className={classes.headingNumber}>{i + 1}.</span> {causalChain.title}
                </span>
                {causalChain?.json?.causal_chain && (
                  <span>{countNodes(causalChain.json.causal_chain)}</span>
                )}
              </div>
              <span className={classes.chips}>
                {causalChain?.json?.causal_chain &&
                  // parse through chains
                  Object.values(causalChain.json.causal_chain)
                    // flatten through 'nodes'
                    .flatMap(value => Object.entries(value))
                    // extract event types and sum value
                    .map(([eventType, events]) => ({
                      type: eventType,
                      sum: events.reduce((acc, curr) => {
                        // eslint-disable-next-line no-param-reassign
                        acc += curr.mag;
                        return acc;
                      }, 0)
                    }))
                    // render chip per event type
                    .map(chip => (
                      <AlembicTitleChip
                        key={chip.type}
                        title={`${chip.sum}`}
                        body={getAttentionActionTitle(chip.type)}
                      />
                    ))}
              </span>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <CausalTimeline causalChainSummary={causalChain.summary} />
              <Grid container justifyContent="center">
                <Button
                  className={classes.footerButton}
                  variant="contained"
                  onClick={e => expandJustify(e, causalChain)}
                >
                  JUSTIFY
                </Button>
              </Grid>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

CausalAccordion.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  expandJustify: PropTypes.func.isRequired
};

CausalAccordion.defaultProps = {
  justifyData: null
};

export default CausalAccordion;
