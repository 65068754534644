/* eslint-disable react/no-children-prop */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    height: '80vh'
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
});

/**
 * @method
 * @summary This component renders the results and justify sections for the GraphFish UI
 * @name StayTuned
 * @return {Object} - React JSX
 */

const StayTuned = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <div className={classes.title} style={{ margin: '12px 0px' }}>
        No intelligence brief available for this time period
      </div>
    </Grid>
  );
};

export default StayTuned;
